'use strict'

export function getLoadingContainer(overlay = true, sticky = true){
    return(
        '<div class="load-container ' + (sticky ? 'load-container--sticky' : '') + '">' +
            '<div class="load-ellipsis">' +
                '<div></div>'  +
                '<div></div>'  +
                '<div></div>'  +
                '<div></div>'  +
            '</div>' +
        '</div>' +
        (overlay ? '<div class="load-container-bg"></div>' : '')
    )
}

export function addLoadingContainer($scope, config = [], big = true) {
    if(big){
        $scope.prepend(getLoadingContainer(...config));
    }else{
        if($scope.css('position') !== 'absolute' || 'relative'){
            $scope.css({
                'position' : 'relative'
            });
        }
        $scope.prepend(getLoadingContainerSmall(...config));
    }
}

export function removeLoadingContainer($scope) {
    $scope.find('.load-container:not(.load--backend), .load-container-bg:not(.load--backend), .load-container-small:not(.load--backend)').remove();
}

export function getLoadingContainerSmall(overlay = true, sticky = false, color = false) {
    return(
        '<div class="load-container-small' +
        (overlay ? ' load-container-small--bg' : '') +
        (color ? ' load-container-small--dark' : '') +
        '">' +
        '<div class="loader-small">' +
        '<div></div>' +
        '<div></div>' +
        '<div></div>' +
        '<div></div>' +
        '</div>' +
        '</div>'
    )
}

export function getSuccessContainer() {
    return(
        '<div class="loader-success-container bg-success">' +
        '<i class="icon icon-check text-white"></i>' +
        '</div>'
    )
}

export function addSuccessContainer($scope) {
    if($scope.css('position') !== 'absolute' || 'relative'){
        $scope.css({
            'position' : 'relative'
        });
    }
    $scope.prepend(getSuccessContainer());
    setTimeout(function () {
        $scope.find('.loader-success-container').remove();
    }, 4000)
}
