import app from "app/Application";
import Module from "modules/Module";

export default class BirthdayPicker extends Module {
    constructor(props){
        super(props);
        this.birthdayPickers = []
    }

    start($scope){
        const $birthdayPickers = $scope.find('.js-birthday-picker');

        $birthdayPickers.each((_, el) => {
            this.birthdayPickers = new BdayPicker($(el))
        })
    }
}

class BdayPicker {
    constructor($scope){
        this.$container = $scope;
        this.$day = this.$container.find('.js-birthday-picker__days');
        this.$month = this.$container.find('.js-birthday-picker__months');
        this.$year = this.$container.find('.js-birthday-picker__years');

        this.$month.on('change', () => this.updateNumberOfDays())
        this.$year.on('change', () => this.updateNumberOfDays())
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    updateNumberOfDays() {
        const days = this.daysInMonth(this.$month.val(), this.$year.val());

        if(days < this.$day.val()){
            this.$day.html('');
            for (let i = 1; i < days + 1 ; i++) {
                this.$day.append($('<option />').val(i).html(i));
            }
        }
    }
}
