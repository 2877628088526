import Module from "modules/Module";
import fetch from "./fetch";

export default class AutoSubmitForm extends Module {

    constructor(props) {
        super(props);
    }

    start($scope) {

        let $form = $scope.find(".js-autoSubmit__form");
        let $timer = $scope.find(".js-autoSubmit__timer");

        let delay = $form.data("delay" || 4);
        let time = parseInt(delay);

        let interval = window.setInterval(function () {
            time = time - 1;
            $timer.text(time);

            if (time <= 0) {
                // const formData = new FormData($form[0]);
                // const params = new URLSearchParams(formData);
                // fetch($form.attr("action"), {
                //     method: 'POST',
                //     body: formData
                // });
                clearInterval(interval);
                $form.submit();
            }
        }, 1000)
    }
}
