import Module from "../../modules/Module";
import fetch from '../utilities/fetch';
import {showNotification} from "../elements/alert-notification";
import {addLoadingContainer, removeLoadingContainer} from "../utilities/loading";

export default class RatingsUpDown extends Module {

    constructor(props) {
        super(props);
    }

    start() {
        this.$triggers = this.$('.js-rating__upvote, .js-rating__downvote');
        this.$triggers.on('click', this.vote.bind(this));

        this.triggerLocks = this.triggerLocks || [];
    }

    vote(e) {
        e.preventDefault();

        const
            $target     = $(e.delegateTarget),
            $container  = $target.closest('.js-rating__container'),
            $triggers   = $container.find('.js-rating__upvote, .js-rating__downvote'),
            href = $target.data("href");
        let
            lock = $target.data("lock");

        if (this.triggerLocks.indexOf(lock) > -1) {
            return;
        }

        addLoadingContainer($container, [false, false, true], false)
        this.triggerLocks.push(lock); // activate lock

        let req = fetch(href);

        showNotification(req);

        req
            .then((res) => {
                return res.clone().json()
            })
            .then((res) => {
                if (res.success) {
                    const $counter = $container.find('.js-rating__upvote-sum');

                    $counter.html(res.rating.up - res.rating.down);
                    $triggers.removeClass("text-primary");

                    if(res.voted){
                        $target.addClass("text-primary");
                    }

                }
            }).finally(() => {
                this.triggerLocks.splice(this.triggerLocks.indexOf(lock), 1); // release lock
                removeLoadingContainer($container);
        })



    }

}
