import throwError from './throw-error';
import app from "../../app/Application";

export function getConfig() {
    if (!app && app.config && app.config.config) {
        throwError(`No modules loaded in ${app}.`);
    }
    return app.config.config;
}

export function getConfigValue(param) {
    if (typeof param !== 'object'){
        param = {
            name: param,
            optional: false,
            validator: null
        }
    }

    let config = getConfig();
    if (!config) {
        return;
    }

    let configValue = config[param.name];
    if (configValue) {
        if (param.validator) {
            if (param.validator(configValue)) {
                return configValue;
            } else {
                throwError(
                    param.validator.message || `Value of config "${param.name}" did not match the validator "${param.validator.name}". Value was` ,
                    configValue
                );
            }
        } else {
            return configValue;
        }
    } else if (!param.optional) {
        throwError(`Missing config "${param.name}". Value of "${param.name}" was not set in "${app}"`, config);
    }
}

export function requireConfigValues (configs) {
    return configs.map(configValue => getConfigValue(configValue));
}
