import app from "app/Application";
import Module from "modules/Module";
import asyncAppend from "../elements/async-append";
import {getLoadingContainer, removeLoadingContainer} from "../utilities/loading";
import fetch from "../utilities/fetch";


export default class ForumAdmin extends Module {

    constructor(props){
        super(props);
        this.forumResponses = [];
    }

    start($scope){
        const $responses = $scope.find('.js-forum-admin__response');

        $responses.each((_, el) => {
            this.forumResponses.push(new ForumAdminResponse($(el)));
        })
    }


}




class ForumAdminResponse{
    constructor($scope) {
        this.$respose = $scope;
        this.$removeResponseLink = $scope.find('.js-forum-admin__delete-response')
        this.$removeResponseLink.on('click', (e) => forumAdminDeleteResponse(e, this))
    }
}


function forumAdminDeleteResponse(e, $scope) {

    e.preventDefault();
    const url = $scope.$removeResponseLink.attr('href');

    asyncAppend({
        $target: $scope.$respose,
        $loading: $(getLoadingContainer(true, false)).appendTo($scope.$respose),
    }, fetch(url)).then((res) => {
        if(res.success){
            //todo @backend - return html so you can enable it again
            $scope.$respose.remove();
        }
    }).finally(() => {
        removeLoadingContainer($scope.$respose);
    })
}