import app from "app/Application";
import Module from "modules/Module";
import $ from "jquery";
import tagsinput from "@adactive/bootstrap-tagsinput"

export default class TagsInput extends Module{

    start($scope) {
        let $inputs = $scope.find('.js-tags-input');

        $inputs.each((_, input) =>{
            const
                $input = $(input),
                maxTags = $input.data('max-tags') ? parseInt($input.data('max-tags')) : 5,
                maxChars = $input.data('max-chars') ? parseInt($input.data('max-chars')) : 20;

            $input.tagsinput({
                maxTags: maxTags,
                maxChars: maxChars,
                focusClass: 'input100--focused'
            })
        })
    }
};
