'use strict'
import Module from "../Module";
import { debounce } from 'throttle-debounce'

export class spinnerInit extends Module {
    constructor(props){
        super(props);
        this.NumberSpinner = [];
    }

    start() {
        this.$spinner = this.$('.js-number-spinner')
        this.$spinner.each((_, el) => {
            this.NumberSpinner.push(new NumberSpinner($(el)));
        })
    }
}

export class NumberSpinner extends Module{

    constructor(props){
        super(props);

        this.$input      = this.$('.js-number-spinner__input')
        this.$minusBtn   = this.$('.js-number-spinner__minus')
        this.$plusBtn    = this.$('.js-number-spinner__plus')

        this.value = this.$input.val();

        if(!this.value){
            this.value = 0;
            this.$input.val(this.value);
        }

        this.start();
    }

    start(){
        this.$input.on('input', (e) => {
            if(e.target.value === ''){
                this.value = 0;
                return
            }
            const parseValue = parseInt(e.target.value);
            if (!isNaN(parseValue) && parseValue >= 0 && parseValue !== this.value) {
                this.value = parseValue;
                this.$input.val(parseValue);
                triggerChange();
            }else{
                this.$input.val(this.value)
            }
        })

        this.$minusBtn.on('click', (e) => {
            this.value = this.value > 0 ? --this.value : 0;
            this.$input.val(this.value);
            this.$input.trigger('before:change');
            triggerChange();
        })

        this.$plusBtn.on('click', (e) => {
            this.value = ++this.value;
            this.$input.val(this.value);
            this.$input.trigger('before:change');
            triggerChange();
        })

        this.$input.on('change', () => {
            if(this.$input.val() !== this.value){
                this.$input.val(this.value)
            }
        })

        const triggerChange = debounce(750, () => {
            this.$input.trigger('change');
        })
    }

}