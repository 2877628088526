import Module from "../../Module";
import app from "../../../app/Application";

export default class ProductgridHeader extends Module {

    start($scope){
        let $button     = this.$('.js-productgrid-header__btn'),
            $collapse   = this.$('#productgrid-header-collapse');

        console.log($button, $collapse)

        /**
         * hiding buttons in productgrid-header
         */
        $collapse.on('show.bs.collapse', function () {
            $button.slideUp();
        })
        $collapse.on('hide.bs.collapse', function () {
            $button.slideDown();
        })
    }
}



