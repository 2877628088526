"use strict";

let missingTranslations = new Set();

export function translate(string) {
    if (typeof _translations !== 'object') {
        console.warn('Global translation object _translations missing');
        return string;
    }

    if (!_translations[string]) {
        if (!missingTranslations.has(string)) {
            console.warn('Could not translate "' + string + '". Translation not found in translation object', _translations);
            missingTranslations.add(string);
        }

        return string;
    }

    return _translations[string];
}

export function getMissingTranslations() {
    return missingTranslations;
}

window.getMissingTranslations = getMissingTranslations;
