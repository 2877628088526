import app from "app/Application";
import Module from "modules/Module";
import hljs from 'highlight.js';

export class CodeHighlights extends Module {
    start(){
        this.codeSnippets = [];
    }

    onWorkerMessage(payload){
        const codeSnippet = this.codeSnippets[payload.data.id];
        codeSnippet.innerHTML = payload.data.result;
        codeSnippet.parentNode.setAttribute('data-language', payload.data.language)
        codeSnippet.classList.add("code-highlight-initialized");
    }

    addCodeHighlight(element){
        if(!this.worker){
            this.worker = new Worker(app.getConfig('HighlightCodeWorkerUrl'));
            this.worker.onmessage = (payload) => this.onWorkerMessage(payload)
        }
        this.codeSnippets.push(element)
        element.setAttribute("code-highlight-id", (this.codeSnippets.length - 1))
        this.worker.postMessage({
            id: this.codeSnippets.length -1,
            language: element.classList[0],
            element: this.codeSnippets[this.codeSnippets.length - 1].textContent
        })

    }

    removeCodeHighlight(element){
        if(element.classList.contains('code-highlight-initialized')){
            this.codeSnippets.splice(element.getAttribute('code-highlight-id') ,1);
            return true
        }
        else{
            return false
        }
    }
}

export class CodeHighlight extends Module{

    start($scope) {
        const $highlightSnippets = $scope.find('.js-hljs')

        if($highlightSnippets.length){
            $highlightSnippets.each((_,el) => {
                app.getSingleton('CodeHighlights').addCodeHighlight(el)
            })
        }
    }
}