import app from "app/Application";
import Module from "modules/Module";
import asyncAppend from "../elements/async-append";
import {getLoadingContainer, removeLoadingContainer} from "../utilities/loading";
import fetch from "../utilities/fetch";
import {isParsleyForm, isValid} from "../elements/parsley-bootstrap-validation";

export default class AjaxFormHistory extends Module {

    constructor(props){
        super(props);
        this.ajaxForm = {}
    }

    start($scope){
        $(window).on('popstate', (e) => {
            if(e.originalEvent.state && e.originalEvent.state.lastLoadParams){
                this.ajaxForm.submit(e.originalEvent.state.lastLoadParams, true);
            }
        });

        const $ajaxForm = $scope.find('.js-ajax-form-history').eq(0);

        this.ajaxForm = new AjaxForm($ajaxForm);

        let newState = $.extend({}, history.state, {
            'lastLoadParams': this.ajaxForm.getUrlParams().toString(),
        });

        history.replaceState(newState, document.title);
    }
}


class AjaxForm {

    constructor($scope){
        this.$scope                 = $scope;
        this.pendingRequest         = null;
        this.$resultContainer       = this.$scope.find('.js-ajax-form-history__result');
        this.$notificationContainer = this.$scope.find('.js-ajax-form-history__notification');
        this.lastLoadParams         = null;
        this.bindResultEvents();
    }

    bindResultEvents() {
        this.$form = this.$scope.find('.js-ajax-form-history__form');
        this.url   = this.$form.attr('action');
        if(this.$form.length){
            this.$form.on('submit', (...args) => this.submitForm(...args))
            this.$form.on('change', (...args) => this.submitForm(...args))
        }


        this.$link = this.$scope.find('.js-ajax-form-history__link');
        if(this.$link.length){
            this.$link.on('click', (...args) => this.clickForm(...args))
        }
    }

    getUrlParams(){
        const formData = new FormData(this.$form[0]);
        const params = new URLSearchParams(formData);

        return params;
    }

    clickForm(e){
        e.preventDefault();
        if (isParsleyForm(this.$form) && !isValid(this.$form)) {
            return;
        }

        const params = this.getUrlParams();
        const linkParams = $(e.currentTarget).data('ajax-form-history-params');

        for(let key in linkParams){
            params.append(key, linkParams[key]);
        }

        this.submit(params)
    }

    submitForm(e){
        e.preventDefault();

        if (isParsleyForm(this.$form) && !isValid(this.$form)) {
            return;
        }

        this.submit(this.getUrlParams())
    }

    submit(formData, silent = false){
        this.lastLoadParams = formData;

        this.pendingRequest = fetch(this.url, {
            method: 'POST',
            body: formData
        });

        asyncAppend({
            $target: this.$resultContainer,
            $loading: $(getLoadingContainer(true, false)).appendTo(this.$resultContainer),
            $notifications: this.$notificationContainer
        }, this.pendingRequest).then((res) => {
            if(res.success){
                if(res.success && res.history && !silent){
                    window.history.pushState(
                        {
                            'lastLoadParams': this.lastLoadParams.toString()
                        },
                        res.history.title,
                        res.history.url)
                }
                this.bindResultEvents();
            }
        }).finally(() => {
            removeLoadingContainer(this.$resultContainer);
        })
    };
}