import 'whatwg-fetch'; // IE10 Polyfill
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; //IE Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill

export default function(url, options = {}) {
    let controller = new AbortController();
    let signal = controller.signal;

    let headers = undefined;
    if(options.method && options.method.toUpperCase() === 'POST' || options.body){
        headers = {
            'X-Requested-With' : 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            ...options.headers
        }
    }else{
        headers = {
            'X-Requested-With' : 'XMLHttpRequest',
            ...options.headers
        }
    }

    if(options.method && options.method.toUpperCase() === 'GET' && options.body) {
        url = addSearchParamsToUrl(url, new URLSearchParams(options.body));
        delete options.body;
    }

    let pendingRequest = fetch(url, {
        method: options.body ? 'POST' : 'GET',
        signal: signal,
        ...options,
        headers
    });

    pendingRequest.abort = function () {
        controller.abort();
        pendingRequest = null;
    };
    pendingRequest.controller = controller;
    pendingRequest.signal = signal;

    return pendingRequest;
}

export function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}



