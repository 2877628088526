import app from "app/Application";
import Module from "modules/Module";

import fetch from '../utilities/fetch'; // IE10 Polyfill
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import { debounce } from "debounce";
import throwError from "../elements/throw-error";
import asyncAppend from "../elements/async-append";
import {getLoadingContainer, removeLoadingContainer} from "../utilities/loading";

export class AjaxModal extends Module {
    constructor(props) {
        super(props);
    }

    start(){
        this.$ajaxModal = this.$('.js-ajax-modal');
        this.$ajaxModalContent = this.$('.js-ajax-modal__content');
        this.pendingRequest;
    }

    isModalOpen(){
        return this.$ajaxModal.hasClass('show')
    }

    closeModal(){
        if(this.isModalOpen){
            this.$ajaxModal.modal('hide')
        }
    }

    showModal(){
        this.$ajaxModal.modal('show')
    }

    openModal(e){
        const $link = $(e.currentTarget)
        const url = $link.attr('href');

        e.preventDefault();

        if (this.pendingRequest) {
            this.pendingRequest.abort();
            this.pendingRequest = null;
        }

        this.closeModal();
        this.$ajaxModalContent.empty();

        this.pendingRequest = fetch(url)

        asyncAppend({
            $target: this.$ajaxModalContent,
            $loading: $(getLoadingContainer(true, false)).appendTo(this.$ajaxModalContent),
        }, this.pendingRequest).finally(() => {
            if (!this.pendingRequest) {
                removeLoadingContainer(this.$cartDrawerContainer);
            }
        }).then((res) => {
          if(res.link){
              setTimeout(() => {
                  window.location.replace(res.link);
              }, 1500)
          }
        }).catch(() => {
            this.closeModal();
        })

        this.showModal();

    }
}

export class AddAjaxModalLinks extends Module {

    constructor(props) {
        super(props);
        this.ajaxModal = app.getSingleton('AjaxModal') ? app.getSingleton('AjaxModal') : throwError('no ajax-modal instance found')
    }

    start(){
        if(this.ajaxModal){
            this.$ajaxModalLinks = this.$('.js-ajax-modal-link')
            this.$ajaxModalLinks.on('click', (e) => this.ajaxModal.openModal(e))
        }else{
            throwError('could not add new ajax-modal links - AjaxModal instance not working')
        }
    }
}