import app from "../../app/Application";
import Module from "../../modules/Module";
import $ from "jquery";
import fetch from '../utilities/fetch'; // IE10 Polyfill

import ClassicEditor from '../../vendor/ckeditor/ckeditor';


import {translate} from "../elements/translations";
import throwError, {throwWarning} from "../elements/throw-error";

const defaultOptions = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'codeBlock', 'imageUpload'],
        codeBlock: {
        languages: [
            { language: 'plaintext', label: 'Plain text', class: 'text js-hljs' },

            { language: 'html', label: 'HTML', class: 'html js-hljs js-hljs-html'  },

            { language: 'php', label: 'PHP', class: 'php js-hljs js-hljs-php'  },

            { language: 'javascript', label: 'JavaScript', class: 'javascript js-hljs js-hljs-javascript' },

            { language: 'typescript', label: 'TypeScript', class: 'typescript js-hljs js-hljs-typescript' },

            { language: 'css', label: 'CSS', class: 'css js-hljs js-hljs-CSS' },

            { language: 'python', label: 'Python', class: 'python js-hljs js-hljs-python' }
        ]
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: '',
    },
    link: {
        decorators: {
            addTargetToExternalLinks: {
                mode: 'automatic',
                callback: url => {return true},
                attributes: {
                    target: '_blank',
                }
            }
        }
    },
    image: {
        toolbar:  [ 'imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:side' ]
    },
    mediaEmbed: {
        removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'dailymotion', 'spotify' ],
        previewsInData: true
    }
    // This value must be kept in sync with the language defined in webpack.config.js.
    // language: 'en'
};

export default class Editors extends Module {

    constructor(props){
        super(props);
        this.editors = {}
        ClassicEditor.defaultConfig = this.setupConfig()

        jQuery.fn.cleanWhitespace = function() {
            this.contents().filter(
                function() { return (this.nodeType == 3 && !/\S/.test(this.nodeValue)); })
                .remove();
            return this;
        }


        //modal focus fix for popups inside a ckeditor instance
        jQuery.fn.modal.Constructor.prototype._enforceFocus = function () {
            var _this5 = this;

            $(document).on('focusin.bs.modal', function (event) {
                const $target = $(event.target);

                if (document !== event.target && _this5._element !== event.target && $(_this5._element).has(event.target).length === 0) {
                    if($target.closest('.ck-balloon-panel').length){
                        event.target.focus();
                    }else{
                        _this5._element.focus();
                    }
                }
            })
        };


    }

    setupConfig(){
        return {
            ...defaultOptions,
            simpleUpload :{
                uploadUrl: app.getConfig("editorImageUploadUrl")
            }
        }
    }

    getEditor(name){
        return this.editors[name]
    }

    start(){
        this.$('.js-editor').each((_, el) => {
            this.initEditor($(el))
        })
        this.$('.js-editor__add-quote').each((_, el) => {
            new Quote($(el))
        })
    }

    addEditor($scope){
        if($scope.hasClass('js-editor')){
            this.initEditor($scope)
        }else{
            const $editor = $scope.find('.js-editor')
            if($editor.length){
                this.initEditor($editor);
            }else{
                throwWarning('tried to initialize editor in ' + $scope + ' but no editor has been found.')
            }
        }
    }

    initEditor($scope){
        const $editor = $($scope);
        // if(this.editors[$editor.attr('id')]){
        //     throwError('an editor with id ' + $editor.attr('id') + ' has already been initialized.')
        // }else{
            const options = app.getSingleton('Editor').setupConfig();
            const notifyUrl = $scope.data('notifiable-users');

            if(notifyUrl){
                options.mention = {
                    feeds: [
                        {
                            marker: '@',
                            feed: getFeedItems,
                            itemRenderer: customItemRenderer
                        }
                    ],
                    url: notifyUrl
                }
            }

            new Editor($editor, options).then((res) => {
                const _editor = res;
                this.editors[$editor.attr('id')] = _editor;

                const $form = $editor.closest('form');

                _editor.editor.ui.focusTracker.on( 'change:isFocused', ( evt, name, focused ) => {
                    if(focused){
                        $editor.addClass('editor--focused')
                    }else{
                        //blur goes here
                        $editor.removeClass('editor--focused')
                    }
                } );

                if($form.length){
                    $editor.attr('novalidate', 'novalidate')
                    $editor.removeAttr('required')

                    $form.on('submit', (e) => {
                        const editorData = _editor.editor.getData();
                        if(!editorData.length){
                            e.preventDefault();
                        }
                    });

                }
            });
        // }

    }
}

class Quote {
    constructor($scope){
        this.$scope = $scope
        this.targetId = this.$scope.data('editor-quote-target')
        this.userId = this.$scope.data('user-id');
        this.userName = this.$scope.data('user-name');
        this.content = this.$scope.closest('article').find('.wysiwyg').clone();
        this.content.find('blockquote, .quote').remove().cleanWhitespace()

        this.$scope.on('click', (...args) => this.addQuote(...args))
    }

    addQuote(e){
        e.preventDefault();

        const data = {
            userId: this.userId,
            user: this.userName,
            content: this.content.cleanWhitespace().html()
        };

        const editor = app.getSingleton('Editor').getEditor(this.targetId);
        if(editor){
            editor.editor.execute( 'insertQuote', data);
            editor.editor.editing.view.focus()
        }
    }
}

export class AddEditor extends Module {

    constructor(props){
        super(props);
    }

    start(){
        const $editors = this.$('.js-editor')
        $editors.each((_,el) => {
            app.getSingleton('Editor').addEditor($(el))
        })
    }
}

class Editor {
    constructor($scope, options){
        return Promise.resolve(ClassicEditor
            .create($scope[0], options)
            .then(editor => {
                this.editor = editor;
                return this;
            })
            .catch(error => {
                console.error('There was a problem initializing the editor.', error);
            })
        );
    }
}

function getFeedItems(queryText ) {
    let url = this.config._config.mention.url;

    if(queryText.length){
        url += '?q=' + queryText;
    }

    return fetch(url).then((res) => {
        return res.clone().json()
    }).then(res => {
        if(res.success){
            return res.customers
        }
    })
}


function customItemRenderer(item) {
    const itemElement = document.createElement( 'span' );

    itemElement.classList.add( 'custom-item' );
    itemElement.textContent = `${ item.name } `;

    return itemElement;

}