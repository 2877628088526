import app from "../../app/Application";
import Module from "../../modules/Module";

import $ from "jquery";
import fetch from "../utilities/fetch"

export class InitCartBadge extends Module {
    start(){
        const $cartBadges = this.$('.js-cart-badge')
        if($cartBadges.length){
            this.previewUrl = app.getConfig('cartCountUrl');
            fetch(this.previewUrl).then((res) => {
                return res.clone().json()
            }).then((res) => {
                if(res.success){
                    $cartBadges.each((_, el) => {
                        const $badge = $(el);
                        if(parseInt(res.count) > 0){
                            $badge.html(res.count)
                            $badge.addClass('is-active')
                        }
                    })
                }
            })
        }
    }
}

export class CartBadge extends Module {
    start(){
        const $cartBadges = this.$('.js-cart-badge')
        $cartBadges.each((_, el) => {
            this.initCartBadge($(el));
        })
    }

    initCartBadge($badge){
        $(window).on('cart:updateBadge', (e, data) => {
            if(parseInt(data.count) > 0){
                $badge.removeClass('is-active')
                $badge[0].offsetWidth;
                $badge.html(data.count)
                $badge.addClass('is-active')
            }else{
                $badge.removeClass('is-active')
            }
        })
    }
}