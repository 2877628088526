import $ from "jquery";
import mapObject from "object.map";

export function normalizeSelector(selector) {
    switch (typeof selector) {
        case 'string':
            return $container => $container.find(selector)
                .add($container.is(selector) ? $container: null);
        case 'function':
            return selector;
        default:
            return (selector instanceof $)
                ? () => selector
                : () => $(selector);
    }
}

export function getElementBySelector(selector, $container) {
    return normalizeSelector(selector)($container)
}

export function getElementsBySelectorObject(selectorObject, $container) {
    return mapObject(selectorObject, selector => getElementBySelector(selector, $container));
}
