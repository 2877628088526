'use strict'
import app from "./../../app/Application";
import Module from "../Module";

import asyncAppend from "../elements/async-append";
import {getLoadingContainer, removeLoadingContainer} from "../utilities/loading";
import fetch from "../utilities/fetch"

export class PdsFormInit extends Module{
    start(){

        let newState = $.extend({}, history.state, {
            'productId': app.getConfig('originalProductId'),
        });

        history.replaceState(newState, document.title);

        $(window).on('popstate', (e) => {
            if(e.originalEvent.state && e.originalEvent.state.productId){
                if(this.pendingRequest){
                    this.pendingRequest.abort();
                    this.pendingRequest = null;
                }
                this.loadProduct(e);
            }
        });
    }

    loadProduct(e){
        const
            url                 = app.getConfig('variantHistoryUrl'),
            productID           = e.originalEvent.state.productId,
            $loadingContainer   = $('body').find('.js-pds-form__container');

        this.pendingRequest = fetch(url, {
            method: 'GET',
            body: {
                productId: productID
            }
        })

        asyncAppend({
            $target: $loadingContainer,
            $loading: $(getLoadingContainer(true, false)).appendTo($loadingContainer),
            $notifications: this.$('.js-pds-form__notifications')
        }, this.pendingRequest).finally(() => {
            if(!this.pendingRequest){
                removeLoadingContainer($loadingContainer);
            }
        });
    }


}

export class PdsForm extends Module {
    start(){
        this.$form = this.$('.js-pds-form__form');
        if(this.$form) {
            this.init()
        }
    }

    init(){
        this.$form.on('submit', this.pdsSubmit.bind(this))

    }

    pdsSubmit(e, $target){
        e.preventDefault()

        if(this.pendingRequest){
            this.pendingRequest.abort();
            this.pendingRequest = null;
        }

        const
            url                 = this.$form.attr('action'),
            formData            = $(e.target).serializeArray(),
            lastFilterField     = $target.attr('name'),
            lastFilterValue     = $target.val() ,
            $loadingContainer   = $('body').find('.js-pds-form__container');

        this.pendingRequest = fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                formData: formData,
                lastFilterField: lastFilterField,
                lastFilterValue: lastFilterValue
            })
        });

        asyncAppend({
            $target: $loadingContainer,
            $loading: $(getLoadingContainer(true, false)).appendTo($loadingContainer),
            $notifications: this.$('.js-pds-form__notifications') 
        },this.pendingRequest).then((res) => {
            if(res.success && res.history){
                window.history.pushState(
                    {
                        'productId': res.history.data
                    },
                    res.history.title,
                    res.history.url)
            }
        }
        ).finally(() => {
            removeLoadingContainer($loadingContainer);
        })
    }
}