"use strict";
import $ from 'jquery'
import { default as throwError, throwWarning } from "./throw-error";
import {  getConfigValue } from './config-utils'

export function initInScope ($scope) {
    let $forms = $scope.find('.js-parsley');
    loadParsley().then(function () {
        $forms.each((i, form) => {
            let $form = $(form);
            if($form.is('form')){
                $forms.parsley(options);
            } else {
                throwWarning('Element with Class .js-parsley is not Type FORM: ', $form)
                $form.find(':input[type=submit]').on('click', () => {
                    isValid($form);
                })
            }
        })
    });
}

function extendParsleyAccessibility(){
    window.Parsley.on('field:error', function ($input) {
        let parsleyId = $input.__id__;
        let fieldClass = $input.__class__;

        if (fieldClass === 'ParsleyFieldMultiple') {
            parsleyId = 'multiple-' + $input.$element.data('parsley-multiple');
        }

        $input.$element.attr({
            'aria-invalid': "true",
            'aria-describedby': 'parsley-id-'+ parsleyId
        });
    });
    window.Parsley.on('field:success', function ($input) {
        $input.$element.removeAttr('aria-invalid aria-describedby');
    });
}


export const options = {
    successClass: 'has-success',
    errorClass: 'has-error',
    classHandler: function (_el) {
        return _el.$element.closest('.form-group, .js-parsley__form-group');
    },
    errorsContainer: function (_el) {
        let errorContainer = _el.$element.closest('.form-group, .js-parsley__form-group').find('.form-errors, .js-parsley__form-errors');
        if (errorContainer && errorContainer.length > 0) {
            return errorContainer;
        }
    },
    // nur felder validieren die sichtbar sind
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden"
};

let promise;
export function loadParsley() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function (resolve, reject) {
        import('parsleyjs').then(function () {
            extendParsleyAccessibility();
            if (getConfigValue('lang') && getConfigValue('lang') !== 'en') {
                import(/* webpackChunkName: "parsley-lang-package-" */'parsleyjs/dist/i18n/' +  getConfigValue('lang') + '.js').then(function () {
                    resolve();
                }).catch(function (reason) {
                    /*fallback if there is no package*/
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (reason) {
            reject();
        });
    });

    return promise;
}

export function isParsleyForm ($form) {
    return $form.is('.js-parsley');
}

// make sure parsley is loaded if you call this function initially (e.g. wrap the call in a loadParsley.then())
export function isValid($form) {
    if(!$.fn.parsley){
        throwError(`Trying to Validate with Parsley, but Parsley is not Loaded! Try to wrap the call in a loadParsley.then().`);
        return
    }
    if($form.length > 1){
        throwWarning('isValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $form)
        $form = $form.eq(0);
    }

    if($form.is('form')){
        if (!$form.data('Parsley')) {
            /* if the form is not initialized */
            $form.parsley(options);
        }
        return $form.data('Parsley').validate();
    }else{
        throwWarning(`Using parsley without form.`);
        let $inputs = $form.find(':input'),
            isValid = true;
        $inputs.each(function () {
            if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                isValid = false
            }
        });
        return isValid
    }
}


export function whenValid($scope) {
    return new Promise((resolve) => {
        let isValid = true;

        if($scope.length > 1){
            throwWarning('whenValid Function only accepts 1 Element! Auto selecting first Element in $scope.', $scope)
            $scope = $scope.eq(0);
        }

        return loadParsley().then(() => {
            if($scope.is('form')){
                if (!$scope.data('Parsley')) {
                    /* if the form is not initialized */
                    $scope.parsley(options);
                }
                isValid = $scope.data('Parsley').validate();
            }else{
                throwWarning(`Using parsley without form.`);
                $scope.find(':input').each(function () {
                    if(!$(this).prop("disabled") && $(this).parsley(options).validate() !== true) {
                        isValid = false
                    }
                });
            }

            resolve(isValid)
        })
    })
}

