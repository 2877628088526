'use strict'
import Module from "../Module";

export default class SubmitForm extends Module {
    start() {
        this.$clickTriggers = this.$('.js-submitForm__click');
        this.$changeTriggers = this.$('.js-submitForm__change');

        this.$clickTriggers.on('click', this.clickTrigger.bind(this))
        this.$changeTriggers.on('click', this.changeTrigger.bind(this))
    }

    clickTrigger(e) {
        const
            $target     = $(e.delegateTarget),
            $targetForm = $target.data('submitForm-target') ? $($target.data('submitForm-target')) : $target.closest('form');

        if($targetForm.length){
            $targetForm.trigger('submit', [$target])
        }else{

        }
    }

    changeTrigger(e){
        const
            $target     = $(e.delegateTarget),
            $targetForm = $target.data('submitForm-target') ? $($target.data('submitForm-target')) : $target.closest('form');

        if($targetForm.length){
            $targetForm.trigger('submit', [$target])
        }else{

        }
    }
}