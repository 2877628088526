import app from "../../app/Application";
import Module from "../../modules/Module";

import $ from "jquery";
import util from 'bootstrap/js/src/util'
import fetch from '../utilities/fetch';
import asyncAppend from '../elements/async-append';
import {getLoadingContainer, removeLoadingContainer} from "../utilities/loading";

export class CartDrawer extends Module {
    constructor(props){
        super(props);
        this.cartOpen = false;
        this.cartLoaded = false;
        this.pendingRequest = null;
    }

    start(){
        this.$cartDrawer = this.$('.js-cart-drawer');
        if(this.$cartDrawer){
            this.$cartDrawerContainer = this.$cartDrawer.find('.js-cart-drawer__html')
            this.initCartDrawer()
        }
        $(window).on('cart:updateCart', this.bindCartActions.bind(this))
    }

    initCartDrawer() {
        $(window).on('cart:updateCart', (e, data) => {
            this.$cartDrawerContainer.empty().append(data.html);
            app.startModules(this.$cartDrawerContainer, app.config.modules);
            this.cartLoaded = true;
        })

        //initial load
        this.previewUrl = this.$cartDrawer.data('preview-url');
        this.updateUrl  = this.$cartDrawerContainer.attr('action')
        this.loadCart()
    }


    loadCart(){
        if(this.previewUrl) {
            if (this.pendingRequest) {
                this.pendingRequest.abort();
                this.pendingRequest = null;
            }


            this.pendingRequest = fetch(this.previewUrl)

            asyncAppend({
                $target: this.$cartDrawerContainer,
                $loading: $(getLoadingContainer(true, false)).appendTo(this.$cartDrawerContainer),
                $notifications: this.$('.js-pds-form__notifications')
            }, this.pendingRequest).then((res) => {
                this.cartLoaded = true;
            }).finally(() => {
                if (!this.pendingRequest) {
                    removeLoadingContainer(this.$cartDrawerContainer);
                }
            }).then(() => {
                this.bindCartActions();
                this.pendingRequest = null;
            });

        }
    }

    updateCart(formData = new FormData(), removeID = null){
        if(this.updateUrl) {
            if (this.pendingRequest) {
                this.pendingRequest.abort();
                this.pendingRequest = null;
            }

            if (removeID) {
                formData.append("removeId", removeID)
            }

            this.pendingRequest = fetch(this.updateUrl, {
                body: new URLSearchParams(formData)
            })

            asyncAppend({
                $target: this.$cartDrawerContainer,
                $loading: $(getLoadingContainer(true, false)).appendTo(this.$cartDrawerContainer),
                $notifications: this.$('.js-pds-form__notifications')
            }, this.pendingRequest).then((res) => {
                this.cartLoaded = true;
                $(window).trigger('cart:updateBadge', {count: res.count})
            }).then(() => {
                this.bindCartActions();
                this.pendingRequest = null;
            }).finally(() => {
                if (!this.pendingRequest) {
                    removeLoadingContainer(this.$cartDrawerContainer);
                }
            })

        }
    }

    bindCartActions(){
        const
            $deleteBtns         = this.$cartDrawerContainer.find('.js-drawer-cart__item-remove'),
            $numberSpinners     = this.$cartDrawerContainer.find('.js-drawer-cart__item-amount');

        $deleteBtns.on('click', (e) => {
            const
                $button = $(e.delegateTarget),
                deleteId = $button.data('drawer-cart-id'),
                formData = new FormData(this.$cartDrawerContainer[0]);

            this.updateCart(formData, deleteId)
        })

        $numberSpinners.on('change', () => {
            const formData = new FormData(this.$cartDrawerContainer[0]);
            this.updateCart(formData)
        })

        $numberSpinners.on('before:change', () => {
            this.$cartDrawerContainer.find('.js-drawer-cart__submit').addClass('pointer-events-none')
        })
    }

    openCartDrawer(){
        if(!this.cartLoaded && !this.pendingRequest) {
            this.loadCart()
        }
        this.$cartDrawer.addClass('is-active');
        const backdrop = $('<div class="modal-backdrop hide"></div>').appendTo(document.body)
        console.log(util);
        util.reflow(backdrop[0])
        backdrop.removeClass('hide').addClass('fade show').on('click', this.closeCartDrawer.bind(this));
        $('body').addClass('modal-open');
        this.cartOpen = true;
    }

    closeCartDrawer(){
        this.$cartDrawer.removeClass('is-active');
        $(".modal-backdrop").remove();
        $('body').removeClass('modal-open');
        this.cartOpen = false;
    }
}

export class CartDrawerTriggers extends Module {

    constructor(props){
        super(props);
        this.CartDrawer = app.getSingleton("CartDrawer");
    }

    start(){
        this.$openCartDrawer = this.$('.js-cart-drawer__open');
        if(this.$openCartDrawer.length){
            this.initTriggers();
        }
        this.$closeCartDrawer = this.$('.js-cart-drawer__close');
        if(this.$closeCartDrawer.length){
            this.closeDrawer();
        }
    }

    initTriggers(){
        this.$openCartDrawer.on('click', (e) => {
            e.preventDefault();
            this.CartDrawer.openCartDrawer()
        })
    }

    closeDrawer(){
        this.$closeCartDrawer.on('click', (e) => {
            e.preventDefault();
            this.CartDrawer.closeCartDrawer();
        })

    }

}