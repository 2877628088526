import app from "../../app/Application";
import Module from "../../modules/Module";

import $ from "jquery";
import throwError from "../elements/throw-error"
import { showNotification } from "../elements/alert-notification"
import { addLoadingContainer, removeLoadingContainer, addSuccessContainer} from "../utilities/loading";
import fetch from "../utilities/fetch"
import AjaxForm from "../AjaxForm";
import {isParsleyForm, isValid} from "../elements/parsley-bootstrap-validation";


export default class AddToCart extends Module {

    constructor($scope) {
        super($scope);
        this.url = app.getConfig("addToCartUrl");
    }

    start() {
        if(!this.url){
            return
        }
        const
            $cartBtns = this.$('.js-add-to-cart');

        $cartBtns.each((_,el) => {
            this.initCartBtn($(el))
        })
    }

    initCartBtn($button) {
        const
            articleId = $button.data('product-id')

        let pendingRequest = null;

        const addToCart = () => {
            if(pendingRequest){
                pendingRequest.abort();
                pendingRequest = null;
            }

            addLoadingContainer($button,[],false)

            let formData;

            if($button.data('product-form')){
                const $form = $button.closest('form');
                if (isParsleyForm($form) && !isValid($form)) {
                    return;
                }
                formData = $button.closest('form').serializeArray();
            }

            pendingRequest = fetch(this.url, {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({
                        formData: formData,
                        articleId : articleId,
                    }),
            });

            pendingRequest.then((res) => {
                return res.clone().json()
            })
                .then((res) => {
                    if(res.success){
                        notifyCart(res.count, res.html);
                        addSuccessContainer($button);
                    }
                })
                .finally(() => {
                    removeLoadingContainer($button)
                })
                .catch((error) => {
                    if(error.name == 'AbortError'){
                        return;
                    }else {
                        console.error(error)
                    }
                })
            showNotification(pendingRequest);
        }

        $button.on('click', () => addToCart())
    }

    stop() {

    }
};


function notifyCart(count, html) {
    $(window).trigger('cart:updateBadge', {count: count})
    $(window).trigger('cart:updateCart', {html: html})
}
