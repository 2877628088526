import app from "app/Application";
import Module from "../../modules/Module";

import * as timeago from 'timeago.js/esm/index.js';

export default class Timeago extends Module{

    constructor(props) {
        super(props);
        // register('my-locale', 'en'); //todo put locale here
    }

    start(){
        this.timestamps = this.$('.js-timeago__timestamp')
        this.timestamps.each((_,el) => {
            new Timestamp(el)
        })
    }


}


class Timestamp{
    constructor(scope){
        this._$scope = scope
        this.timeago = timeago.render(this._$scope)
    }

    stopRender(){
        timeago.cancel(this._$scope)
    }
}