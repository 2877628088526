'use strict'
import Module from "../Module";
import Croppie from 'croppie';

import fetch from './../utilities/fetch'; // IE10 Polyfill

import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill

export default class Dropzones extends Module {

    constructor(props){
        super(props);

    }

    start(){
        const $dropzones = this.$('.js-dropzone');
        $dropzones.each((_, el) => {
            new Dropzone($(el))
        })
    }
}


class Dropzone {
    constructor(props) {
        this.$container = props;
        this.$form      = this.$container.find('.js-dropzone__form');
        this.$input     = this.$container.find('.js-dropzone__input');
        this.$label     = this.$container.find('.js-dropzone__label');
        this.$errorMsg  = this.$container.find('.js-dropzone__error');
        this.$preview   = this.$container.find('.js-dropzone__preview');
        this.$croppie   = this.$container.find('.js-dropzone__croppie');
        this.$submitBtn = this.$container.find('.js-dropzone__submit-btn');
        this.options    = this.$container.data('dropzone-options');
        this.maxFiles   = this.$container.data('dropzone-max-files') ? this.$container.data('dropzone-max-files') : 1;
        this.maxFileSize= this.$container.data('dropzone-max-filesize-b') ? this.$container.data('dropzone-max-filesize-b') : 10000000;
        this.croppie    = null;
        this.pendingRequest = false;
        this.droppedFiles   = false;
        this.validFileExtensions = this.$container.data('dropzone-valid-file-extensions') ? this.$container.data('dropzone-valid-file-extensions') : [".jpg", ".jpeg", ".gif", ".png"];

        if(this.isDropzoneAble()){
            this.initDropzone()
        }else{
            alert("please update your browser bro")
        }
    }

    isDropzoneAble(){
        const div = document.createElement( 'div' );
        return ( ( 'draggable' in div ) || ( 'ondragstart' in div && 'ondrop' in div ) ) && 'FormData' in window && 'FileReader' in window;
    }

    initDropzone(){
       this.$form.addClass( 'has-advanced-upload' ); // letting the CSS part to know drag&drop is supported by the browser

        [ 'drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop' ].forEach( ( event ) => {
            this.$form[0].addEventListener( event, ( e ) => {
                // preventing the unwanted behaviours
                e.preventDefault();
                e.stopPropagation();
            });
        });
        [ 'dragover', 'dragenter' ].forEach( ( event ) => {
            this.$form[0].addEventListener( event, () => {
                this.$form.addClass( 'is-dragover' );
            });
        });
        [ 'dragleave', 'dragend', 'drop' ].forEach( ( event ) => {
            this.$form[0].addEventListener( event, () => {
                this.$form.removeClass( 'is-dragover' );
            });
        });

        this.$input[0].addEventListener( 'change', ( e ) => {
            this.allFiles = e.dataTransfer.files
            this.initFilePreview()
        });

        this.$form[0].addEventListener( 'drop', ( e ) => {
            this.allFiles = e.dataTransfer.files
            this.initFilePreview()
        });

        this.$form[0].addEventListener('submit', ( e ) => {
            if(!this.droppedFiles){
                return
            }

            this.$form[0].classList.add( 'is-uploading' );
            this.$form[0].classList.remove( 'is-error' );

            if(this.options && this.options.Croppie) {
                $("<input/>").attr("type", "hidden")
                    .attr("name", "croppy-settings")
                    .attr("value", JSON.stringify(this.options.Croppie))
                    .appendTo(this.$form)

                $("<input/>").attr("type", "hidden")
                    .attr("name", "croppy-coords")
                    .attr("value", JSON.stringify(this.croppie.get()))
                    .appendTo(this.$form)
            }
            this.$input.get(0).files = this.allFiles
        })
    }

    initFilePreview(){
        this.droppedFiles = Object.keys(this.allFiles).slice(0,this.maxFiles).reduce((result, key) => {
            if(this.isValidateFileExtension(this.allFiles[key]) && this.isValidFileSize(this.allFiles[key])){
                result[key] = this.allFiles[key]
                return result;
            }
        }, {});

        if(!this.droppedFiles){
            alert("file not valid")
            return;
        }

        if(this.options && this.options.Croppie){
            this.initCroppie(this.options.Croppie).then((res) => {
                this.croppie = res;
            });
        }else{
            this.showFiles();
        }
    }

    isValidFileSize(file){
        return (file.size && file.size <= this.maxFileSize)
    }

    async initCroppie(options){
        const defaultOptions = {
            viewport: {
                width: 200,
                height: 200,
                type: 'circle'
            },
            boundary: {
                width: 300,
                height: 300
            }
        }

        this.options.Croppie = {
            ...defaultOptions,
            ...options
        }

        return new Promise(((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(this.droppedFiles[0])
            if(this.croppie){
                this.croppie.destroy();
            }
            reader.onloadend = () => {
                let croppie = new Croppie(this.$croppie[0], {
                    url: reader.result,
                    ...defaultOptions,
                    ...options
                })
                resolve(croppie);
            }
        }));
    }

    async showFiles(){

        console.log("files list", this.droppedFiles)

        let $list = [];

        for(const i in this.droppedFiles){
            let filePreview = await this.previewFile(this.droppedFiles[i]);
            $list.push(await filePreview)
        }
        this.$preview.empty().append($list)
    }

    async previewFile(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function() {
                let $container = $('<div class="dropzone__preview-img"></div>')
                let img = document.createElement('div')
                // img.src = reader.result
                img.style.backgroundImage = "url('" + reader.result + "')";
                $container.append($(img).addClass('dropzone__img'))
                $container.append("<span class='dropzone__preview-name'>" + file.name + "</span>")
                resolve($container)
            }

        })
    }

    isValidateFileExtension(file) {
        return (new RegExp('(' + this.validFileExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(file.name.toLowerCase());
    }
}