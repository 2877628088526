'use strict'
import { pwstrength } from 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
import { translate } from '../elements/translations'
import Module from "../Module";

export default class PwCheck extends Module {

    start($scope){
        const $inputContainers = $scope.find('.js-pw-check');
        $inputContainers.each((_, el) => this.initPwCheck($(el)))
    }

    initPwCheck($container) {
        let options = {};

        options.ui = {
            container: $container,
            viewports: {
                progress: $container.find('.js-pw-check__container')
            },
            showVerdictsInsideProgressBar: true
        };
        options.common = {
            debug: true,
            onLoad: function () {
                $('#messages').text('Start typing password');
            }
        };
        options.i18n = {
            t: function (key) {
                return translate('password.check.' + key);
            }
        };

        $container.find('.js-pw-check__input').pwstrength(options);
    }


}


