import app from "app/Application";
import Module from "modules/Module";
import $ from "jquery";
import select2 from "select2"

export default class Select extends Module{

    start($scope) {
        let $selects = $scope.find('.js-select2');

        $selects.each((_, select) =>{
            const
                $select     = $(select),
                $wrapper    = $select.closest('.wrap-input100'),
                $focusInput = $wrapper.find('.focus-input100')

            $select.select2({
                minimumResultsForSearch: 20,
                dropdownParent: $select.parent().find('.dropDownSelect2')
            }).on('select2:open', function() {
                $focusInput.addClass('eff-focus-selection')
            }).on('select2:close', function() {
                $focusInput.removeClass('eff-focus-selection')
            })

            if($select.hasClass("select2-hidden-accessible")){
                const $container = $select.parent().find('.select2-container');

                var mut = new MutationObserver(function(mutationsList, observer){
                    for(let mutation of mutationsList) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                            if($container.hasClass('select2-container--focus')){
                                $focusInput.addClass('eff-focus-selection')
                            }else{
                                $focusInput.removeClass('eff-focus-selection')
                            }
                        }
                    }
                });
                mut.observe($container[0],{
                    'attributes': true
                });
            }
        })
    }
};


